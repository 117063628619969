<template>
  <!--begin::Card-->
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("PROFILE_FORMS.DISPLAY.HEADER") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{
          $t("PROFILE_FORMS.DISPLAY.HEADER_DESCRIPTION")
        }}</span>
      </div>
      <div class="card-toolbar" v-if="changed">
        <b-overlay :show="submissionStates.submitting" rounded="sm">
          <button
            type="submit"
            v-if="!$v.form.$anyError"
            class="btn btn-success mr-2"
            @click="save()"
            ref="kt_save_changes"
          >
            {{ $t("PROFILE_FORMS.DISPLAY.BUTTON_SAVE") }}
          </button>
        </b-overlay>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          {{ $t("PROFILE_FORMS.DISPLAY.BUTTON_CANCEL") }}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="profile_change_form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">
              {{ $t("PROFILE_FORMS.DISPLAY.BASIC_INFORMATION") }}
            </h5>
          </div>
        </div>

        <!--begin::User Name-->
        <InputGenericGroup
          id="name"
          :model="$v.form.name"
          :label="computedNameInputs.label"
          :showInline="true"
          class="my-5"
          :submissionStates="submissionStates"
          :error="computedNameInputs.error"
        ></InputGenericGroup>
        <!--end::User Name-->

        <!--begin::Display Name-->
        <InputGenericGroup
          id="display_name"
          :model="$v.form.display_name"
          :label="computedDisplayNameInputs.label"
          :showInline="true"
          class="my-5"
          :submissionStates="submissionStates"
          :error="computedDisplayNameInputs.error"
        ></InputGenericGroup>
        <!--end::Display Name-->

        <!--begin::Email-->
        <InputGenericGroup
          id="email"
          :model="$v.current_email"
          :label="$t('PROFILE_FORMS.INPUTS.EMAIL')"
          :showInline="true"
          class="my-5"
          :submissionStates="submissionStates"
          :disabled="true"
        ></InputGenericGroup>
        <!--end::Email-->

        <!--begin::Profile Picture-->
        <div
          id="avatar"
          role="group"
          class="form-row form-group w-100 mb-0 text-left"
        >
          <label
            id="avatar"
            for="avatar"
            class="col-sm-4 col-lg-3 col-form-label"
            >{{ $t("REGISTER.BASIC.AVATAR") }}</label
          >
          <IntegratedDropzone
            class="col"
            :assetReservation="assetReservationData"
            :idx="1"
            :projectAssets="current_avator"
            :wholeWidth="true"
            :removable="true"
            classStyle="sm"
            @asset_reservation_created="avatorCreate($event)"
            @asset_reservation_cancelled="avatorCancel()"
            @remove_project_asset="avatorRemove($event)"
          >
          </IntegratedDropzone>
        </div>

        <!--end::Profile Picture-->
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { UPDATE_CLIENT_USER_INFO } from "@/core/services/store/core/user.module";
import { PRELOAD_CLIENT } from "@/core/services/store/core/client.module";
import { maxLength, required } from "vuelidate/lib/validators";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";
import InputGenericGroup from "@/view/components/form-inputs/GenericInputGroup.vue";

export default {
  name: "PersonalInformation",
  data() {
    return {
      current_email: null,
      current_avator: [],
      form: {
        name: "",
        avatar: null,
        avatar_remove: null,
        display_name: ""
      },
      changed: false,
      submissionStates: {
        submitting: false
      }
    };
  },

  watch: {
    form: {
      handler: function(val) {
        if (
          val.name !== this.currentUser.calculatedName ||
          val.display_name !== this.currentUser.display_name
        ) {
          this.changed = true;
        }
        this.$v.form.$touch();
      },
      deep: true
    }
  },

  components: {
    InputGenericGroup,
    IntegratedDropzone
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255)
      },
      display_name: {
        required,
        maxLength: maxLength(255)
      },
      avatar: {},
      avatar_remove: {}
    },
    current_email: {
      required
    }
  },

  mounted() {
    this.formReset();
  },
  methods: {
    formReset() {
      this.form.name = this.currentUser.calculatedName;
      this.form.display_name = this.currentUser.display_name;
      //this.form.avatar = this.currentUser.avatar;
      this.form.avatar_remove = null;
      this.current_email = this.currentUser.calculatedEmail;
      if (this.currentUser.project_asset) {
        this.current_avator = [this.currentUser.project_asset];
      }
      this.changed = false;
    },

    avatorCreate(event) {
      this.form.avatar = event;
      this.changed = true;
    },

    avatorCancel() {
      this.form.avatar = null;
      this.changed = true;
    },

    avatorRemove(event) {
      this.form.avatar = null;
      this.form.avatar_remove = event;
      this.current_avator = [];
      this.changed = true;
    },

    save() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {
        userId: this.currentUser.id,
        clientId: this.clientInfo.id,
        data: {
          name: this.form.name,
          display_name: this.form.display_name,
          avatar: this.form.avatar,
          avatar_remove: this.form.avatar_remove,
          email: this.current_email,
          system_client_role_id: 1,
          system_country_code: this.currentUser.system_country_code,
          system_language_code: this.currentUser.system_language_code,
          system_timezone_code: this.currentUser.system_timezone_code
        }
      };
      this.$store
        .dispatch(UPDATE_CLIENT_USER_INFO, payload)
        .then(() => {
          Swal.fire({
            title: "",
            text: this.$t("TOASTS.DEFAULT.SUCCESS"),
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            allowOutsideClick: false
          }).then(() => {
            this.$store
              .dispatch(PRELOAD_CLIENT, this.clientInfo.id)
              .then(() => {
                this.$router.go();
              });
          });
        })
        .catch(() => {
          this.scrollTop();
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },

    scrollTop: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    cancel() {
      this.formReset();
    }
  },
  computed: {
    ...mapGetters(["currentUser", "clientInfo"]),

    assetReservationData: function() {
      let data = {
        scope: "user",
        data: {
          directory: "avatars",
          new: 1
        }
      };
      return data;
    },
    firstInitial: function() {
      return this.currentUser.display_name[0].toUpperCase();
    },

    picture() {
      return this.currentUser.signedAvatar;
    },

    computedNameInputs() {
      let label = this.$t("PROFILE_FORMS.INPUTS.NAME");
      let error = this.$t("PROFILE_FORMS.ERROR.NAME");
      return { label: label, error: error };
    },

    computedDisplayNameInputs() {
      let label = this.$t("PROFILE_FORMS.INPUTS.DISPLAY_NAME");
      let error = this.$t("PROFILE_FORMS.ERROR.DISPLAY_NAME");
      return { label: label, error: error };
    }
  }
};
</script>
