<template>
  <div>
    <div v-if="currentUser.system_register_type_id != 2">
      <!--begin::ErrorBox-->
      <ErrorBox :errors="userErrors" @clear_errors="clearErrors"> </ErrorBox>
      <!--end::ErrorBox-->
      <PersonalInformation></PersonalInformation>

      <ChangePassword></ChangePassword>      
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ChangePassword from "@/view/pages/profile/ChangePassword.vue";
import PersonalInformation from "@/view/pages/profile/PersonalInformation.vue";
import { CLEAR_USER_ERRORS } from "@/core/services/store/core/user.module";

import ErrorBox from "@/view/components/ErrorBox";
export default {
  name: "EditProfile",
  components: { PersonalInformation, ChangePassword, ErrorBox },

  data() {
    return {};
  },

  methods: {
    clearErrors: function() {
      this.$store.dispatch(CLEAR_USER_ERRORS);
    }
  },

  computed: {
    ...mapGetters(["userErrors","currentUser"])
  }
};
</script>
