<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("PROFILE_FORMS.DISPLAY.PASSWORD_HEADER") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          {{ $t("PROFILE_FORMS.DISPLAY.PASSWORD_HEADER_DESCRIPTION") }}</span
        >
      </div>
      <div class="card-toolbar" v-if="changed">
        <b-overlay :show="submissionStates.submitting" rounded="sm">
          <button
            type="submit"
            v-if="!$v.form.$anyError"
            class="btn btn-success mr-2"
            @click="save()"
            ref="kt_save_changes"
          >
            {{ $t("PROFILE_FORMS.DISPLAY.BUTTON_SAVE") }}
          </button>
        </b-overlay>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          {{ $t("PROFILE_FORMS.DISPLAY.BUTTON_CANCEL") }}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="password_change_form">
      <div class="card-body">
        <!--begin::New Password-->
        <InputGenericGroup
          id="input-password"
          class="my-5"
          :model="$v.form.password"
          :label="computedPasswordInputs.label"
          :showInline="true"
          :submissionStates="submissionStates"
          :error="computedPasswordInputs.error"
          type="password"
        ></InputGenericGroup>
        <!--end::New Password-->

        <!--begin::Verify Password-->
        <InputGenericGroup
          id="input-password-confirm"
          class="my-5"
          :model="$v.form.password_confirmation"
          :label="computedConfirmPasswordInputs.label"
          :showInline="true"
          :submissionStates="submissionStates"
          :error="computedConfirmPasswordInputs.error"
          type="password"
        ></InputGenericGroup>
        <!--end::Verify Password-->
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { UPDATE_CLIENT_USER_INFO } from "@/core/services/store/core/user.module";
import { PRELOAD_CLIENT } from "@/core/services/store/core/client.module";
import InputGenericGroup from "@/view/components/form-inputs/GenericInputGroup.vue";
import {
  sameAs,
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "ChangePassword",
  data() {
    return {
      form: {
        password: "",
        password_confirmation: ""
      },
      changed: false,
      submissionStates: {
        submitting: false
      }
    };
  },

  watch: {
    form: {
      handler: function(val) {
        if (val) {
          this.changed = true;
        }
        this.$v.form.$touch();
      },
      deep: true
    }
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(24)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
        minLength: minLength(8),
        maxLength: maxLength(24)
      }
    }
  },
  components: {
    InputGenericGroup
  },
  methods: {
    save() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {
        userId: this.currentUser.id,
        clientId: this.clientInfo.id,
        data: {
          name: this.currentUser.calculatedName,
          display_name: this.currentUser.display_name,
          email: this.currentUser.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          system_client_role_id: 1,
          system_country_code: this.currentUser.system_country_code,
          system_language_code: this.currentUser.system_language_code,
          system_timezone_code: this.currentUser.system_timezone_code
        }
      };

      this.$store
        .dispatch(UPDATE_CLIENT_USER_INFO, payload)
        .then(() => {
          Swal.fire({
            title: "",
            text: this.$t("TOASTS.DEFAULT.SUCCESS"),
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            allowOutsideClick: false
          }).then(() => {
            this.$store
              .dispatch(PRELOAD_CLIENT, this.clientInfo.id)
              .then(() => {
                this.$router.go();
              });
          });
        })
        .catch(() => {
          this.scrollTop();
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    scrollTop: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    cancel() {
      this.form.password = "";
      this.form.password_confirmation = "";
    }
  },
  computed: {
    ...mapGetters(["currentUser", "clientInfo"]),

    computedPasswordInputs() {
      let label = this.$t("PROFILE_FORMS.INPUTS.PASSWORD");
      let error = this.$t("PROFILE_FORMS.ERROR.PASSWORD");
      return { label: label, error: error };
    },

    computedConfirmPasswordInputs() {
      let error = "";
      let label = this.$t("PROFILE_FORMS.INPUTS.PASSWORD_CONFIRM");
      if (this.$v.form.password_confirmation.sameAsPassword !== true) {
        error = this.$t("PROFILE_FORMS.ERROR.NOT_SAME_AS_PASSWORD_CONFIRM");
      } else {
        error = this.$t("PROFILE_FORMS.ERROR.PASSWORD_CONFIRM");
      }
      return { label: label, error: error };
    }
  }
};
</script>
